
import { Component, Vue, Prop, Mixins, Watch } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component({
  components: {
    FormValidator
  }
})
export default class PreguntasDinamicas extends Mixins(FormValidator) {
  @Prop({ required: false })
  private dataPreguntas!: any;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };
  private mounted() {}
  private async selectedFecha(pregunta: any) {
    (this.$refs["qDateProxy" + pregunta.id] as any)[0].hide();
  }
}
