var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.check_loading)?_c('q-form',{ref:"registerForm",staticClass:"row flex-center q-col-gutter-x-xs q-col-gutter-y-lg",on:{"submit":function($event){return _vm.sendTicketAttemp()}}},[(_vm.$store.state.logged && !_vm.$store.state.user.email_verified)?_c('div',{staticClass:"warning text-center col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11"},[_c('label',[_vm._v(" Tu correo aún no esta verificado "),_c('br'),_c('label',{staticStyle:{"font-size":"0.8rem"}},[_vm._v(" Se enviarán actualizaciones y seguimiento de tu solicitud al correo asociado a tu cuenta. ")])])]):_vm._e(),(_vm.mensaje != '' && _vm.$store.state.ticket.motive == 1)?_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"text-left col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('label',{domProps:{"innerHTML":_vm._s(_vm.mensaje)}})])]):_vm._e(),(_vm.canAutoFillAdress && _vm.default_address != 1)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 q-pt-none"},[_c('q-btn',{staticClass:"btn no-shadow self-end float-right",staticStyle:{"height":"100% !imprtant"},attrs:{"color":"principal"},on:{"click":_vm.autoFillAdress}},[_vm._v(" Usar dirección de contacto ")])],1):_vm._e(),(_vm.default_address != 1)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row items-center justify-center"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start"},[_c('div',{class:'col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 row items-center justify-start ' +
              (_vm.$q.screen.xs || _vm.$q.screen.sm ? ' q-pb-lg' : '')},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row flex-center"},[_c('q-select',{staticClass:"q-pb-none full-width",attrs:{"outlined":"","use-input":"","hide-selected":"","fill-input":"","emit-value":"","input-debounce":"500","color":"principal","option-value":"nombre","option-label":"nombre","dense":true,"options-dense":true,"label":_vm.$t('forms.street_request') + ' *',"options":_vm.options_street,"rules":[_vm.formIsRequired],"loading":_vm.street_loading},on:{"filter":_vm.filterStreet},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"principal","name":"place"}})]},proxy:true},{key:"no-option",fn:function(){return [(_vm.street_search_empty)?_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" Escriba su calle para buscar ")])],1):_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" Sin resultados ")])],1)]},proxy:true}],null,false,3558003293),model:{value:(_vm.formData.street),callback:function ($$v) {_vm.$set(_vm.formData, "street", $$v)},expression:"formData.street"}})],1)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 row items-center justify-start"},[_c('div',{class:'col-12 row flex-center ' +
                (!(_vm.$q.screen.xs || _vm.$q.screen.sm) ? 'q-pl-sm' : '')},[_c('q-input',{staticClass:"q-pb-none full-width",attrs:{"outlined":"","color":"principal","type":"number","dense":true,"options-dense":true,"label":"N° *","rules":[_vm.formIsRequired]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"principal","name":"tag"}})]},proxy:true}],null,false,417717031),model:{value:(_vm.formData.street_number),callback:function ($$v) {_vm.$set(_vm.formData, "street_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.street_number"}})],1)])])]):_vm._e(),(_vm.default_address != 1)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row justify-center"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-center"},[_c('q-input',{staticClass:"q-pb-none full-width",attrs:{"outlined":"","color":"principal","dense":true,"options-dense":true,"label":_vm.$t('forms.hint')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"principal","name":"o_my_location"}})]},proxy:true}],null,false,4076589715),model:{value:(_vm.formData.hint),callback:function ($$v) {_vm.$set(_vm.formData, "hint", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.hint"}})],1)]):_vm._e(),(
        _vm.data_preguntas &&
          _vm.data_preguntas.length > 0 &&
          _vm.$store.state.ticket.motive == 1
      )?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row justify-center"},[_c('div',{staticClass:"col-md-11 col-11"},[_c('PreguntasDinamicas',{attrs:{"dataPreguntas":_vm.data_preguntas}})],1)]):_vm._e(),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row justify-center"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-center"},[_c('q-input',{staticClass:"q-pb-none full-width",attrs:{"outlined":"","color":"principal","type":"textarea","maxlength":"900","dense":true,"options-dense":true,"label":'Detalle de la solicitud *',"rules":[_vm.formIsRequired]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"principal","name":"rate_review"}})]},proxy:true}],null,false,1338285106),model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.message"}}),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formData.message.length)+"/900")])],1)]),(
        _vm.requiereDocumento &&
          _vm.mensajeDocumento != '' &&
          _vm.$store.state.ticket.motive == 1
      )?_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"warning text-left col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"},[_c('label',{domProps:{"innerHTML":_vm._s(_vm.mensajeDocumento)}})])]):_vm._e(),_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"row justify-start col-auto full-height text-center vertical-top q-pt-none q-pr-md"},[_c('q-btn',{staticClass:"vertical-top",attrs:{"round":"","color":"principal","size":"xl","icon":"file_upload"},on:{"click":_vm.triggerFilepond}})],1),_c('div',{staticClass:"col"},[_c('file-pond',{ref:"pond",attrs:{"name":"Files","label-idle":"Tamaño máximo de 50 MB para archivos adjuntos","labelFileProcessing":"Subiendo","labelFileLoading":"Cargando","labelFileLoadError":"Error durante la subida","labelFileProcessingComplete":"Subida completada","labelFileProcessingAborted":"Subida cancelada","labelTapToCancel":"Presione para cancelar","labelTapToUndo":"Presione para eliminar","labelButtonRetryItemLoad":"Reintentar","labelFileTypeNotAllowed":"Archivo de tipo no permitido","labelMaxFileSizeExceeded":"El archivo es demasaido grande","labelMaxFileSize":"El tamaño máximo para los archivos es de {filesize}","labelTapToRetry":"Presione para reintentar","labelFileProcessingError":"Error durante la subida","labelFileRemoveError":"Error al quitar el archivo","fileValidateTypeDetectType":_vm.typeDetection,"allow-multiple":true,"allowFileSizeValidation":true,"maxFileSize":'51MB',"maxFiles":10,"fileValidateTypeLabelExpectedTypes":'Solo se permiten archivos {allButLastType} o {lastType}',"fileValidateTypeLabelExpectedTypesMap":{
            'image/*': 'Fotografías',
            'video/*': 'Videos',
            'application/zip': 'Zip',
            'application/rar': 'Rar',
            'application/pdf': 'Pdf',
            'application/doc': 'Doc',
            'application/docx': 'Docx',
            'application/xls': 'Xls',
            'application/xls': 'Xlsx',
            'application/ppt': 'Ppt',
            'application/x-mpegURL': null,
            'application/x-compressed': null,
            'multipart/x-zip': null,
            'application/x-rar-compressed': null,
            'application/vnd.rar': null,
            'application/x-zip-compressed': null,
          },"accepted-file-types":[
            'image/*',
            'video/*',
            'application/x-mpegURL',
            'application/x-compressed',
            'application/x-zip-compressed',
            'application/zip',
            'multipart/x-zip',
            'application/x-rar-compressed',
            'application/vnd.rar',
            'application/rar',
            'application/pdf',
            'application/doc',
            'application/docx',
            'application/xls',
            'application/xlsx',
            'application/ppt',
            'application/vnd.ms-outlook'
          ]}})],1)]),(_vm.$idComuna == 128)?_c('div',{staticClass:"col-11 row justify-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.base_bucket + '/banners/banner-importante.png'}})]):_vm._e(),_c('div',{staticClass:"col-11 q-pt-md q-pb-md q-ml-sm"},[_c('div',{staticClass:"col-11 row justify-center"},[_c('vue-recaptcha',{staticClass:"full-width",attrs:{"sitekey":_vm.captcha_key},on:{"verify":_vm.onVerify,"expired":_vm.onExpired,"error":_vm.onError}},[_c('q-btn',{staticClass:"btn no-shadow q-mt-md full-width",attrs:{"disabled":_vm.disable_button,"dense":"","type":"submit","color":"principal","label":"Finalizar"}})],1)],1)])]):_vm._e(),_c('q-dialog',{model:{value:(_vm.uploadProgressBar),callback:function ($$v) {_vm.uploadProgressBar=$$v},expression:"uploadProgressBar"}},[_c('q-card',{staticClass:"col-12 card-dialog q-mx-xs"},[_c('q-toolbar',{staticClass:"bg-principal"},[_c('q-toolbar-title',{staticClass:"text-start tex-bold text-white q-ml-md card-title"},[_vm._v(" "+_vm._s("Subiendo Archivos")+" ")])],1),_c('q-card-section',[_c('q-linear-progress',{staticClass:"q-mt-md",attrs:{"stripe":"","rounded":"","size":"30px","value":_vm.uploadProgressPercent,"color":"yellow"}},[_c('div',{staticClass:"absolute-full flex flex-center"},[_c('q-badge',{attrs:{"color":"white","text-color":"yellow","label":Math.round(_vm.uploadProgressPercent * 100)}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }