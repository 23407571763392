
import { Component, Vue } from "vue-property-decorator";
import SendTicketForm from "@/components/forms/SendTicketForm.vue";

@Component({
  components: {
    SendTicketForm
  }
})
export default class SendRequest extends Vue {
  private mounted() {
    this.$gtag.event("Detalle Motivo: " + "Solicitud", {
      event_label: "Detalle Motivo",
      event_category: "test",
      value: 1
    });

    window.scrollTo(0, 0);
  }

  private backAction() {
    this.$store.dispatch("setStateToTwo");
  }
}
